import orbit from './audio/orbit.mp3';
import orbit_cover from './album_covers/orbit.png';

import dna from './audio/dna.mp3';
import dna_cover from './album_covers/dna.jpg';

import pyar from './audio/pyar.mp3';
import pyar_cover from './album_covers/tino.jpg';

import sbs from './audio/citygone.mp3';
import sbs_cover from './album_covers/sbs.jpeg';

import casso from './audio/casso.mp3';
import casso_cover from './album_covers/atnkag.jpg';

import cherry from './audio/cherrywine.mp3';
import agag from './album_covers/agag.jpeg';

import alaska from './audio/alaska.mp3';
import alaska_cover from './album_covers/alaska.jpg';


// Track data - easily editable
const tracks = [
  {
    id: 0,
    song_name: 'alaska remix',
    song_src: alaska,
    album_cover: alaska_cover,
    artist: 'adhiv',
    context: 'A remix to Maggie Rogers\' Alaska, where you can rebuild the track layer by layer and play with your own vocal chops.',
    links: [
      { name: 'Interactive Remix', url: 'https://remix.adhiv.com' },
      { name: 'SoundCloud', url: 'https://soundcloud.com/dharbeats/alaska-maggie-rogers-adhiv-remix' },
      { name: 'Youtube', url: 'https://www.youtube.com/watch?v=ZH6k83DRp7E' },
    ]
  },
  {
    id: 1,
    song_name: 'your orbit',
    song_src: orbit,
    album_cover: orbit_cover,
    artist: 'adhiv & third culture',
    context: 'Do you think they\'re saying "stuck" or "f***" ?',
    links: [
      { name: 'Spotify', url: 'https://open.spotify.com/track/63Ab6EmbCjnbo9JG488m91?si=ada4824774d04691' },
      { name: 'Apple Music', url: 'https://music.apple.com/us/album/your-orbit-single/1784036657' }
    ]
  },
  {
    id: 2,
    song_name: 'dna remix',
    song_src: dna,
    album_cover: dna_cover,
    artist: 'adhiv',
    context: 'An electronic remix of Kendrick Lamar\'s DNA',
    links: [
      { name: 'SoundCloud', url: 'https://soundcloud.com/dharbeats/kendrick-lamar-dna-adhiv-remix?si=a8374d1632fc4f3b974150fc37bafa43&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' },
      { name: 'Youtube', url: 'https://www.youtube.com/watch?v=AuURBasNXGA' },
    ]
  },
  {
    id: 5,
    song_name: 'casso',
    song_src: casso,
    album_cover: casso_cover,
    artist: 'third culture',
    context: 'Think I talk too much need to zip it down',
    links: [
      { name: 'Spotify', url: 'https://open.spotify.com/track/0Xz7YZU0aBEsa5RfZTgX3L?si=bf762dd3450c463b' },
      { name: 'Apple Music', url: 'https://music.apple.com/us/album/casso/1797703742?i=1797703745' }
    ]
  },
  {
    id: 3,
    song_name: 'pyar',
    song_src: pyar,
    album_cover: pyar_cover,
    artist: 'adhiv',
    context: 'A remix of the classic Bollywood song "Gum Hai Kisike Pyar Mein"',
    links: [
      { name: 'Soundcloud', url: 'https://soundcloud.com/dharbeats/pyar-gum-hai-kisike-pyar-mein?in=dharbeats/sets/tino&si=64429048b0894d8aa04b20c3bc49525e&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' }
    ]
  },
  {
    id: 4,
    song_name: 'city gone',
    song_src: sbs,
    album_cover: sbs_cover,
    artist: 'third culture',
    context: 'occasionally I throw a guitar solo in the mix',
    links: [
      { name: 'Spotify', url: 'https://open.spotify.com/track/2j9YZySfu9GezasAXZa9ii?si=9035254a499448b1' },
      { name: 'Apple Music', url: 'https://music.apple.com/us/album/city-gone/1739262415?i=1739262424' }
    ]
  },
  {
    id: 6,
    song_name: 'cherry wine',
    song_src: cherry,
    album_cover: agag,
    artist: 'third culture',
    context: 'someone said the saxophone sounds like nujabes',
    links: [
      { name: 'Spotify', url: 'https://open.spotify.com/track/2Icfa393iDWiCYx0siVY21?si=7cbeb84b5cc64100' },
      { name: 'Apple Music', url: 'https://music.apple.com/us/album/cherry-wine/1627526292?i=1627526293' }
    ]
  },
];

export default tracks;
