import React, { Component } from 'react';
import './../styles/Marquee.scss';

class Marquee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                { id: 2, text: "40.7061° N, 73.9477° W", type: 'location' },
                { id: 1, text: "WELCOME TO MY CORNER OF THE INTERNET", type: 'manual' },
                { id: 3, text: "REMIX MY ALASKA REMIX", type: 'manual', link: 'https://remix.adhiv.com', color: '#ff0000' },
                { id: 7, text: "CURRENTLY LISTENING TO: FRANK OCEAN - WHITE FERRARI", type: 'spotify', spotifyData: null },
                { id: 4, text: "YOUR ORBIT STREAMING EVERYWHERE", type: 'manual', link: 'https://open.spotify.com/track/63Ab6EmbCjnbo9JG488m91?si=ZhbW1rJiSDqK_dfWh6p58w' },
                { id: 5, text: "BROOKLYN NY: 72°F, SUNNY", type: 'weather' },
                { id: 6, text: "12:00 AM ET", type: 'time' },

            ],
            isLoading: false,
            error: null,
        };
        
        this.marqueeRef = React.createRef();
        this.timeInterval = null;
        this.spotifyInterval = null;
    }
    

    componentDidMount() {
        // Fetch real weather data
        this.fetchWeatherData();
        // Update time
        this.updateCurrentTime();
        // Fetch Spotify data
        this.fetchSpotifyData();
        
        // Set intervals
        this.timeInterval = setInterval(this.updateCurrentTime, 60000);
        this.spotifyInterval = setInterval(this.fetchSpotifyData, 300000); // Update every 5 minutes
    }

    componentWillUnmount() {
        // Clear intervals when component unmounts
        if (this.timeInterval) {
            clearInterval(this.timeInterval);
        }
        if (this.spotifyInterval) {
            clearInterval(this.spotifyInterval);
        }
    }

    // Update the current Eastern Time
    updateCurrentTime = () => {
        const options = { 
            hour: 'numeric', 
            minute: '2-digit', 
            hour12: true,
            timeZone: 'America/New_York'
        };
        
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const timeString = formatter.format(new Date()) + ' ET';
        
        this.setState(prevState => ({
            items: prevState.items.map(item => 
                item.type === 'time' 
                    ? {...item, text: `${timeString}`}
                    : item
            )
        }));
    }

    // Get weather data from Open Meteo API
    fetchWeatherData = async () => {
        try {
            this.setState({ isLoading: true });
            const BROOKLYN_COORDS = {
                lat: 40.7061,
                lon: -73.9477
            };
            
            const response = await fetch(
                `https://api.open-meteo.com/v1/forecast?latitude=${BROOKLYN_COORDS.lat}&longitude=${BROOKLYN_COORDS.lon}&current_weather=true`
            );
            
            if (!response.ok) {
                throw new Error(`Weather API returned ${response.status}`);
            }
            
            const data = await response.json();
            
            // Extract weather data
            const tempCelsius = data.current_weather.temperature;
            const tempFahrenheit = Math.round((tempCelsius * 9/5) + 32);
            const weathercode = data.current_weather.weathercode;
            
            // Map WMO weathercode to text description
            const weatherDescriptions = {
                0: 'CLEAR SKY',
                1: 'MAINLY CLEAR',
                2: 'PARTLY CLOUDY',
                3: 'OVERCAST',
                45: 'FOG',
                48: 'DEPOSITING RIME FOG',
                51: 'LIGHT DRIZZLE',
                53: 'MODERATE DRIZZLE',
                55: 'DENSE DRIZZLE',
                56: 'LIGHT FREEZING DRIZZLE',
                57: 'DENSE FREEZING DRIZZLE',
                61: 'SLIGHT RAIN',
                63: 'MODERATE RAIN',
                65: 'HEAVY RAIN',
                66: 'LIGHT FREEZING RAIN',
                67: 'HEAVY FREEZING RAIN',
                71: 'SLIGHT SNOW',
                73: 'MODERATE SNOW',
                75: 'HEAVY SNOW',
                77: 'SNOW GRAINS',
                80: 'SLIGHT RAIN SHOWERS',
                81: 'MODERATE RAIN SHOWERS',
                82: 'VIOLENT RAIN SHOWERS',
                85: 'SLIGHT SNOW SHOWERS',
                86: 'HEAVY SNOW SHOWERS',
                95: 'THUNDERSTORM',
                96: 'THUNDERSTORM WITH SLIGHT HAIL',
                99: 'THUNDERSTORM WITH HEAVY HAIL'
            };
            
            const condition = weatherDescriptions[weathercode] || 'UNKNOWN';
            
            // Update state with weather information
            this.setState(prevState => ({
                items: prevState.items.map(item => 
                    item.type === 'weather' 
                        ? {...item, text: `BROOKLYN, NY: ${tempFahrenheit}°F, ${condition}`}
                        : item
                ),
                isLoading: false
            }));
        } catch (error) {
            console.error('Error fetching weather data:', error);
            this.setState({ 
                error: 'Failed to fetch weather data',
                isLoading: false 
            });
        }
    }

    fetchLocationData = async () => {
        try {
            // Location data would be fetched here
            console.log('Location data would be fetched here');
        } catch (error) {
            console.error('Error fetching location data:', error);
        }
    }

    // Format time ago
    formatTimeAgo = (dateString) => {
        const playedAt = new Date(dateString);
        const now = new Date();
        const diffMs = now - playedAt;
        
        // Convert to appropriate time unit
        const diffSec = Math.floor(diffMs / 1000);
        if (diffSec < 60) return `${diffSec}s ago`;
        
        const diffMin = Math.floor(diffSec / 60);
        if (diffMin < 60) return `${diffMin}m ago`;
        
        const diffHour = Math.floor(diffMin / 60);
        if (diffHour < 24) return `${diffHour}h ago`;
        
        const diffDay = Math.floor(diffHour / 24);
        return `${diffDay}d ago`;
    }

    fetchSpotifyData = async () => {
        try {
            // Determine if in development or production
            const isDev = window.location.hostname === 'localhost';
            let space = ' ';
            
            // In development, we need to handle CORS differently
            let data;
            if (isDev) {
                // Option 1: If you have a proxy setup in package.json
                const response = await fetch('/.netlify/functions/spotify');
                
                // Option 2: Add mode no-cors (but will only work for GET requests and data access is limited)
                // const response = await fetch('http://localhost:8888/.netlify/functions/spotify', {
                //     mode: 'no-cors',
                //     headers: {
                //         'Content-Type': 'application/json'
                //     }
                // });
                
                // If response not ok, try the mock data for development
                if (!response.ok) {
                    // Mock data for development
                    data = {
                        trackName: "White Ferrari",
                        artist: "Frank Ocean",
                        songLink: "https://open.spotify.com/track/2LMkwUfqC6S6s6qDVlEuzV",
                        albumArtUrl: "https://i.scdn.co/image/ab67616d00004851c5648cbd8126a2e172a48023", 
                        playedAt: new Date(Date.now() - 1000 * 60 * 15).toISOString() // 15 minutes ago
                    };
                } else {
                    data = await response.json();
                }
            } else {
                // Production or non-localhost environment
                const response = await fetch('/.netlify/functions/spotify');
                if (!response.ok) {
                    throw new Error(`Spotify API returned ${response.status}`);
                }
                data = await response.json();
            }
            
            // Format the "time ago" string
            const timeAgo = this.formatTimeAgo(data.playedAt);
            
            // Update state with Spotify information
            this.setState(prevState => ({
                items: prevState.items.map(item => 
                    item.type === 'spotify' 
                        ? {
                            ...item, 
                            text: space + `${data.artist} - ${data.trackName}` + space,
                            link: data.songLink,
                            spotifyData: {
                                ...data,
                                timeAgo
                            }
                          }
                        : item
                )
            }));
        } catch (error) {
            console.error('Error fetching Spotify data:', error);
            let space = ' ';
            // Use mock data on error in development
            if (window.location.hostname === 'localhost') {
                const mockData = {
                    trackName: "White Ferrari",
                    artist: "Frank Ocean",
                    songLink: "https://open.spotify.com/track/2LMkwUfqC6S6s6qDVlEuzV",
                    albumArtUrl: "https://i.scdn.co/image/ab67616d00004851c5648cbd8126a2e172a48023",
                    playedAt: new Date(Date.now() - 1000 * 60 * 15).toISOString() // 15 minutes ago
                };
                const timeAgo = this.formatTimeAgo(mockData.playedAt);
                
                this.setState(prevState => ({
                    items: prevState.items.map(item => 
                        item.type === 'spotify' 
                            ? {
                                ...item, 
                                text: ` ${mockData.artist} - ${mockData.trackName} ` + space,
                                link: mockData.songLink,
                                spotifyData: {
                                    ...mockData,
                                    timeAgo
                                }
                              }
                            : item
                    )
                }));
            } else {
                this.setState(prevState => ({
                    items: prevState.items.map(item => 
                        item.type === 'spotify' 
                            ? {
                                ...item, 
                                text: 'RECENTLY PLAYED: NOT AVAILABLE',
                                spotifyData: {
                                    error: true
                                }
                              }
                            : item
                    )
                }));
            }
        }
    }

    renderMarqueeItems() {
        const { items } = this.state;
        const renderedItems = [];
        let space = ' ';
        
        items.forEach((item, index) => {
            if (item.type === 'spotify') {
                // Special rendering for Spotify items, with or without data
                renderedItems.push(
                    <div className="marquee-item" key={`item-${item.id}-${index}`}>
                        <span className="selectable-text spotify-item">
                            <span className="spotify-logo">
                                <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
                                </svg>
                            </span>
                            <span className="spotify-content">
                                {item.spotifyData && !item.spotifyData.error ? (
                                    <>
                                        RECENTLY PLAYED: {'\u00A0'} <a 
                                            href={item.link} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            className="spotify-link"
                                        >
                                            {item.spotifyData.artist} - {item.spotifyData.trackName}
                                        </a>{'\u00A0'}•{'\u00A0'}{item.spotifyData.timeAgo}
                                    </>
                                ) : (
                                    <>RECENTLY PLAYED: NOT AVAILABLE</>
                                )}
                            </span>
                        </span>
                        <span className="separator">/////</span>
                    </div>
                );
            } else {
                // Regular item rendering with support for color
                renderedItems.push(
                    <div className="marquee-item" key={`item-${item.id}-${index}`}>
                        {item.link ? (
                            <a 
                                href={item.link} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="selectable-text"
                                style={item.color ? { color: item.color } : {}}
                            >
                                {item.text}
                            </a>
                        ) : (
                            <span 
                                className="selectable-text"
                                style={item.color ? { color: item.color } : {}}
                            >
                                {item.text}
                            </span>
                        )}
                        {index < items.length - 1 && <span className="separator">/////</span>}
                    </div>
                );
            }
        });
        
        return renderedItems;
    }

    render() {
        const { isLoading, error } = this.state;
        
        if (isLoading) return <div className="marquee-container"></div>;
        if (error) return <div className="marquee-container">Error: {error}</div>;
        
        const marqueeItems = this.renderMarqueeItems();
        
        return (
            <div className="marquee-container">
                <div className="marquee-track">
                    <div className="marquee-content" ref={this.marqueeRef}>
                        {marqueeItems}
                        {marqueeItems}
                        {marqueeItems}
                    </div>
                </div>
            </div>
        );
    }
}

export default Marquee; 