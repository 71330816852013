import React from 'react';
import { useMusicPlayer } from '../context/MusicPlayerContext';
import './../styles/App.scss';
import './../styles/MusicPlayer.scss';

import tracks from '../music_data/tracks';
import GlobalMusicPlayer from './GlobalMusicPlayer';

import pic12 from './../assets/about-12.jpg';
import pic13 from './../assets/about-13.jpg';

const Music = () => {
  const { handleTrackSelect, currentTrack } = useMusicPlayer();

  return (
    <section className="about">
      <div className="about-main">
        <div className="about-section">
          <div className="section-title"><h2>/music</h2></div>
          <div className="section-content">
            <p>I'm an electronic producer influenced by hip hop, rnb, and indie music. I like to build my sound from chopping samples and shaping synths into grooves.</p><br />

            <p>My music is scattered around the internet; <a href="https://open.spotify.com/artist/3su9U8scNTBbNeoQVBfxpI?si=o8IjdShTTtysBLkQAAg45w" target="_blank">spotify</a> / <a href="https://music.apple.com/us/artist/adhiv/1658888888" target="_blank">apple music</a>, <a href="https://soundcloud.com/dharbeats" target="_blank">soundcloud</a>, <a href="https://www.youtube.com/@dharbeats" target="_blank">youtube</a>. I collected a few of my favorite tracks below</p>
          </div>
        </div>

        <div className="about-section">
          <div className="section-title"><h2>click to play</h2></div>
          <div className="section-content">
            <p>A few tracks I've produced:</p>
            <div className="track-list">
              {tracks.map(track => (
                <div 
                  key={track.id} 
                  className={`track-item ${currentTrack && currentTrack.id === track.id ? 'active' : ''}`}
                  onClick={() => handleTrackSelect(track)}
                >
                  <div className="track-image">
                    <img src={track.album_cover} alt={track.song_name} />
                  </div>
                  <div className="track-info">
                    <div className="track-name">{track.song_name}</div>
                    <div className="track-artist">{track.artist}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="about-section">
          <div className="section-title"><h2>projects</h2></div>
          <div className="section-content">
          <ul>
            <li>
              <p><b>remix my remix</b></p>
              <p>An interactive <a href="https://remix.adhiv.com" target="_blank">website</a> remix to Maggie Rogers' Alaska, where you can rebuild the track layer by layer and play with your own vocal chops.</p>
            </li>
            <br />
              <li>
                  <p><b>tino</b></p>
                  <p>A <a href="https://soundcloud.com/dharbeats/sets/tino" target="_blank">beat tape</a> sampling 1970's Bollywood and classical Indian songs into hip hop and electronic music. Made a <a href="https://www.youtube.com/watch?v=SkyfCY1tUwA" target="_blank">visualiser</a> sampling the original movies/references the samples came from.</p>
              </li>
              <br />
              <li>
                  <p><b>dhar beats</b></p>
                  <p>50+ type beats on my <a href="https://www.youtube.com/@dharbeats" target="_blank">Youtube channel</a>, in the style of Mac Miller, Kendrick Lamar, Smino, and more.</p>
              </li>
              <br />
              <li>
                  <p><b>stop by sometime</b></p>
                  <p>Indie acoustic project by third culture. Karthik and Emil travelled to Iceland and filmed a project <a href="https://www.youtube.com/watch?v=QfGfYuilMB0" target="_blank">visualiser</a>.</p>
              </li>
              <br />
              <li>
                  <p><b>all good, all gone</b></p>
                  <p>Produced a hip hop <a href="https://open.spotify.com/album/2Ksxc15RFwkFR5qP2nesBx?si=PW8tFTCKTa23DAc4tFG6fQ" target="_blank">mixtape</a>, influenced by indie, jazz, and soul samples</p>
              </li>
              </ul>
          </div>
        </div>

        <div className="about-section">
          <div className="section-title"><h2>third culture</h2></div>
          <div className="section-content">
            <p>3C is an <a href="https://www.instagram.com/3rdcltr" target="_blank" rel="noopener noreferrer">art collective</a> started with friends from the Bay Area. We're South Asians artists making hip hop + indie music; storytellers raised in a world where nobody told our stories. </p>
          </div>
        </div>
        <div className="about-section">
          <div className="section-title"><h2>performances</h2></div>
          <div className="section-content">
            <ul>
              <li><p>Masala Mixtape, Brooklyn</p></li>
              <li><p>Nublu, Intro for KLD Wave, East Village</p></li>
              <li><p>Live Looping w/ Chloe Catara, Bushwick</p></li>
              <li><p>Guitar for Chloe Catara, Brooklyn Art Cave</p></li>
              <li><p>Guitar for KLD Wave, Breaking Sound</p></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="about-sidebar">
        <div className="about-sidebar-img">
        {currentTrack && (
          <div className="about-sidebar-player">
            <GlobalMusicPlayer tracks={tracks} />
          </div>
        )}
          {/* <img src={pic12} alt="pic12" /> */}
        </div>
        
        <br />
      </div>
    </section>
  );
};

export default Music;
